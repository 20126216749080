import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { useEffect } from "react";

export function EmailSignup() {
  // <script
  //   id="cc_data"
  //   dangerouslySetInnerHTML={{ __html: `var _ctct_m = "c2be83f4b1e8481f38dcdad0e2fdfce6";` }}
  // ></script>
  // <script
  //   id="signupScript"
  //   src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js"
  //   async
  //   defer
  // ></script>

  // load the script in the useEffect
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "cc_data";
    script.innerHTML = `var _ctct_m = "c2be83f4b1e8481f38dcdad0e2fdfce6";`;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.id = "signupScript";
    script2.src = "//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js";
    script2.async = true;
    script2.defer = true;
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <div
      key="ctct-inline-form"
      className="ctct-inline-form"
      data-form-id="3acd4783-6e86-49c4-9f2f-685207557457"
      suppressHydrationWarning
    ></div>
    // <form action="/email-signup" method="post" className="grid gap-6 items-stretch">
    //   <div>
    //     <Label htmlFor="first_name">First Name</Label>
    //     <Input type="text" name="first_name" id="firstName" placeholder="First Name" className="bg-white relative" />
    //   </div>
    //   <div>
    //     <Label htmlFor="last_name">Last Name</Label>
    //     <Input type="text" name="last_name" id="lastName" placeholder="Last Name" className="bg-white relative" />
    //   </div>
    //   <div>
    //     <Label htmlFor="email">Email</Label>
    //     <Input type="email" name="email" id="email" placeholder="Email" className="bg-white relative" />
    //   </div>
    //   <div>
    //     <Button variant="secondary" size="lg">
    //       Sign Up
    //     </Button>
    //   </div>
    // </form>
  );
}

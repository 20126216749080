export function NavLocationMobile({ navigation }: { navigation: any }) {
  const navItems = Object.values(navigation);

  return navItems.map((navItem) => {
    return (
      <li key={`mobile_nav_${navItem.ID}`} className="relative">
        <a href={navItem.url} target={navItem.target} className="px-4 py-2 block relative text-base">
          {/* black with opacity set to 20% on hover */}
          <span className="group-hover/item:bg-black/20 absolute inset-0 z-0" />
          <span className="relative z-10">{navItem.title}</span>
        </a>

        {/* {navItem.children && (
          <ul className="group-hover:block absolute top-full left-0 bg-foreground text-foreground z-10 hidden min-w-64 py-2">
            {navItem.children.map((child) => (
              <li key={child.id}>
                <a href={child.url} className="px-4 py-2 block" target={child.target}>
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )} */}
      </li>
    );
  });
}

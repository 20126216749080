import { DropdownMenuItem } from "@/components/ui/dropdown-menu";

export function NavLocation({ navigation }: { navigation: any }) {
  const navItems = Object.values(navigation);

  return (
    <ul>
      {navItems.map((navItem, index) => {
        return (
          <li key={`desktop_nav_${navItem.ID}`} className="relative">
            <DropdownMenuItem asChild className="text-base focus:bg-black/30 focus:text-primary-foreground">
              <a href={navItem.url} target={navItem.target} className="text-background px-4 py-[2px] block relative">
                {navItem.title}
              </a>
            </DropdownMenuItem>
          </li>
        );
      })}
    </ul>
  );
}

import { FaFacebook, FaXTwitter, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa6";

interface SocialLinkProps {
  links: Array<{
    service: string;
    url: string;
  }>;
}

export function SocialLinks({ links }: SocialLinkProps) {
  const iconMap = {
    facebook: FaFacebook,
    instagram: FaInstagram,
    x: FaXTwitter,
    linkedin: FaLinkedin,
    youtube: FaYoutube,
  };

  return (
    <div className="flex gap-3">
      {links.map((link, index) => {
        const Icon = iconMap[link.service.toLowerCase()];
        return (
          <a
            key={link.url + index}
            href={link.url}
            target="_blank"
            rel="noreferrer noopener"
            className="text-xl font-semibold text-primary"
            title={`Visit us on ${link.service}`}
          >
            <Icon size={24} />
          </a>
        );
      })}
    </div>
  );
}

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "../ui/button";
import { NavLocationMobile } from "./nav-location-mobile";
import { XCircle } from "lucide-react";
import { SheetClose } from "../ui/sheet";

export function NavigationMobile({ nav }) {
  return (
    <div className="h-svh overflow-scroll -m-6 p-6">
      <div className="p-4 flex justify-between">
        <Button asChild variant="secondary" className="text-sm lg:text-base xl:text-lg hover:bg-secondary">
          <a
            href="https://anc.apm.activecommunities.com/fpparks/home?onlineSiteId=0&from_original_cui=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register Online
          </a>
        </Button>
        <SheetClose asChild>
          <Button variant="outline" className="flex text-sm items-center gap-1">
            <XCircle className="w-4" /> Close Menu
          </Button>
        </SheetClose>
      </div>
      <Accordion className="px-4" type="single" collapsible>
        <AccordionItem value="about">
          <AccordionTrigger>About</AccordionTrigger>
          <AccordionContent>
            <ul>
              <NavLocationMobile navigation={nav["about-menu"]} />
            </ul>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="programs">
          <AccordionTrigger>Programs</AccordionTrigger>
          <AccordionContent>
            <ul>
              <NavLocationMobile navigation={nav["programs-menu"]} />
            </ul>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="events">
          <AccordionTrigger>Events</AccordionTrigger>
          <AccordionContent>
            <ul>
              <NavLocationMobile navigation={nav["events-menu"]} />
            </ul>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="ice-arena">
          <AccordionTrigger>Ice Arena</AccordionTrigger>
          <AccordionContent>
            <ul>
              <NavLocationMobile navigation={nav["ice-arena-menu"]} />
            </ul>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="registration">
          <AccordionTrigger>Registration</AccordionTrigger>
          <AccordionContent>
            <ul>
              <NavLocationMobile navigation={nav["registration-menu"]} />
            </ul>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="public-information">
          <AccordionTrigger>Public Information</AccordionTrigger>
          <AccordionContent>
            <ul>
              <NavLocationMobile navigation={nav["public-information-menu"]} />
            </ul>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="facilities">
          <AccordionTrigger>Facilities</AccordionTrigger>
          <AccordionContent>
            <ul>
              <NavLocationMobile navigation={nav["facilities-menu"]} />
            </ul>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="parks">
          <AccordionTrigger>Parks</AccordionTrigger>
          <AccordionContent>
            <ul>
              <NavLocationMobile navigation={nav["parks-menu"]} />
            </ul>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="parties-rentals">
          <AccordionTrigger>Parties & Rentals</AccordionTrigger>
          <AccordionContent>
            <ul>
              <NavLocationMobile navigation={nav["parties-rentals-menu"]} />
            </ul>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

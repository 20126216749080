import { Button } from "../ui/button";
import { SearchInput } from "../search-input";
import { Calendar, Menu, Search } from "lucide-react";
import { GoogleTranslate } from "../google-translate";
import { Navigation } from "./navigation";
import { NavigationMobile } from "./navigation-mobile";
import { useState } from "react";
import { SocialLinks } from "../social-links";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";

export function Header({ navigation, socialLinks }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Sheet>
      <header className="py-3 px-3 border-b bg-white">
        <nav className="flex items-center gap-3">
          <div className="flex-shrink">
            <a href="/" className="">
              <img src="/fppd-logo.svg" alt="Logo" className="h-12 md:h-16 block md:hidden lg:block" />
              <img
                src="/fppd-logo-square.svg"
                className=" max-h-24 hidden md:block lg:hidden"
                alt="Park District of Franklin Park"
              />
            </a>
            <a href="/export-paths" className="hidden">
              Export Paths
            </a>
          </div>
          <div className="flex gap-2 flex-grow md:hidden justify-end text-right relative z-50">
            <Button variant="link" size="icon" className="w-8" asChild>
              <a href="/events" aria-label="Events">
                <Calendar className="w-12" />
              </a>
            </Button>
            <Button variant="link" size="icon" className="w-8" asChild>
              <a href="/search" aria-label="Search">
                <Search className="w-12" />
              </a>
            </Button>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                aria-label="Open navigation menu"
              >
                <Menu className="w-12" />
              </Button>
            </SheetTrigger>
          </div>
          <div className="hidden md:flex flex-col flex-grow items-end justify-end">
            <div className="flex gap-3 items-center">
              <GoogleTranslate />
              <SocialLinks links={socialLinks} />
              <div className="w-64">
                <SearchInput />
              </div>
            </div>
            <div className="mt-4">
              <Navigation nav={navigation} />
            </div>
          </div>
        </nav>
        <SheetContent className="w-screen" onOpenAutoFocus={(event) => event.preventDefault()}>
          <NavigationMobile nav={navigation} />
        </SheetContent>
      </header>
    </Sheet>
  );
}

import { cn } from "~/lib/utils";
import { Button } from "../ui/button";
import { NavLocation } from "./nav-location";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";

// const headerLocations = [
//   "header-menu",
//   "about-menu",
//   "public-information-menu",
//   "facilities-menu",
//   "parties-rentals-menu",
//   "programs-menu",
//   "ice-arena-menu",
//   "parks-menu",
//   "events-menu",
//   "registration-menu",
// ];

export function Navigation({ nav }) {
  if (!nav) return null;
  return (
    // each top level has a mega menu flyout
    <ul className="flex gap-3 lg:gap-6 text-sm lg:text-base xl:text-lg uppercase font-semibold items-center">
      <li>
        <DropdownMenu>
          <DropdownMenuTrigger className="uppercase">About</DropdownMenuTrigger>
          <NavItemDropdownContent>
            <div className="grid grid-cols-2">
              <NavItemDropdownColumn className="bg-theme-green-2">
                <NavGroupHeading>About</NavGroupHeading>
                <NavLocation navigation={nav["about-menu"]} />
              </NavItemDropdownColumn>
              <NavItemDropdownColumn>
                <NavGroupHeading>Public Information</NavGroupHeading>
                <NavLocation navigation={nav["public-information-menu"]} />
              </NavItemDropdownColumn>
            </div>
          </NavItemDropdownContent>
        </DropdownMenu>
      </li>
      <li>
        <DropdownMenu>
          <DropdownMenuTrigger className="uppercase">Programs & Events</DropdownMenuTrigger>
          <NavItemDropdownContent className="min-w-[800px]">
            <div className="grid grid-cols-3">
              <NavItemDropdownColumn className="bg-theme-green-2">
                <NavGroupHeading>Programs</NavGroupHeading>
                <NavLocation navigation={nav["programs-menu"]} />
                <div className="pt-8">
                  <NavGroupHeading>Events</NavGroupHeading>
                  <NavLocation navigation={nav["events-menu"]} />
                </div>
              </NavItemDropdownColumn>
              <NavItemDropdownColumn className="bg-theme-ice-blue">
                <NavGroupHeading>Ice Arena</NavGroupHeading>
                <NavLocation navigation={nav["ice-arena-menu"]} />
              </NavItemDropdownColumn>
              <NavItemDropdownColumn className="bg-theme-purple">
                <NavGroupHeading>Registration</NavGroupHeading>
                <NavLocation navigation={nav["registration-menu"]} />
                <div className="pt-8">
                  <NavGroupHeading>Memberships</NavGroupHeading>
                  <NavLocation navigation={nav["memberships-menu"]} />
                </div>
              </NavItemDropdownColumn>
            </div>
          </NavItemDropdownContent>
        </DropdownMenu>
      </li>
      <li>
        <DropdownMenu>
          <DropdownMenuTrigger className="uppercase">Facilities & Parks</DropdownMenuTrigger>
          <NavItemDropdownContent className="min-w-[700px]">
            <div className="grid grid-cols-2">
              <NavItemDropdownColumn className="bg-theme-green-2">
                <NavGroupHeading>Parks</NavGroupHeading>
                <NavLocation navigation={nav["parks-menu"]} />
                <div className="pt-6 px-4">
                  <DropdownMenuItem asChild>
                    <Button variant="outline" className="bg-transparent text-white" asChild>
                      <a href="/interactive-map">View Interactive Map</a>
                    </Button>
                  </DropdownMenuItem>
                </div>
              </NavItemDropdownColumn>
              <NavItemDropdownColumn className="bg-purple">
                <NavGroupHeading>Facilities</NavGroupHeading>
                <NavLocation navigation={nav["facilities-menu"]} />
                <div className="pt-8">
                  <NavGroupHeading>Parties & Rentals</NavGroupHeading>
                  <NavLocation navigation={nav["parties-rentals-menu"]} />
                </div>
              </NavItemDropdownColumn>
            </div>
          </NavItemDropdownContent>
        </DropdownMenu>
      </li>
      <li>
        <a href="/events">Calendar</a>
      </li>
      <li>
        <Button asChild variant="secondary" className="text-sm lg:text-base xl:text-lg hover:bg-secondary">
          <a
            href="https://anc.apm.activecommunities.com/fpparks/home?onlineSiteId=0&from_original_cui=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register Online
          </a>
        </Button>
      </li>
    </ul>
  );
}

function NavGroupHeading({ children }) {
  return (
    <DropdownMenuLabel asChild className="text-lg font-semibold text-white uppercase px-4 pb-2 underline-offset-4">
      <h3>{children}</h3>
    </DropdownMenuLabel>
  );
}

function NavItemDropdownColumn({ children, className = "" }) {
  return <div className={cn("py-6 px-2", className)}>{children}</div>;
}

function NavItemDropdownContent({ children, className = "" }) {
  return <DropdownMenuContent className={cn("bg-primary p-0 border-0", className)}>{children}</DropdownMenuContent>;
}

import { SocialLinks } from "../social-links";

const facilities = [
  {
    name: "Community Center",
    address: "9560 Franklin Ave",
    city: "Franklin Park",
    state: "IL",
    zip: "60131",
    phone: "(847) 455-2852",
  },
  {
    name: "Centre at North Park",
    address: "10040 Addison St",
    city: "Franklin Park",
    state: "IL",
    zip: "60131",
    phone: "(847) 678-4021",
  },
  {
    name: "Pool on Pacific",
    address: "9715 Pacific Ave",
    city: "Franklin Park",
    state: "IL",
    zip: "60131",
    phone: "(847) 928-8480",
  },
  {
    name: "Ice Arena",
    address: "9711 Waveland Ave",
    city: "Franklin Park",
    state: "IL",
    zip: "60131",
    phone: "(847) 671-4268",
  },
];

export function Footer({ socialLinks }) {
  return (
    <div>
      <div className="max-w-[1440px] mx-auto">
        <footer className="bg-white flex flex-col lg:flex-row justify-center items-center text-center lg:text-left gap-12 py-12 px-6">
          <div>
            <div className="space-y-6">
              <div className=" max-w-[230px] md:max-w-[300px] mx-auto">
                <img src="/fppd-logo-square.svg" className="w-full" alt="Park District of Franklin Park" />
              </div>
              <div className="flex justify-center lg:justify-start">
                <SocialLinks links={socialLinks} />
              </div>
              <div>
                © 2024 Park District of Franklin Park <br /> All Rights Reserved
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row flex-wrap gap-6 justify-center">
            {facilities.map((facility) => (
              <div key={facility.name}>
                <h3 className="font-semibold text-lg">{facility.name}</h3>
                <address className="not-italic">
                  {facility.address}
                  <br />
                  {facility.city}, {facility.state} {facility.zip}
                  <br />
                  {facility.phone}
                </address>
              </div>
            ))}
          </div>
        </footer>
      </div>
      <div className="bg-primary/30  text-center px-4 py-3 text-sm">
        Website Designed and Developed by{" "}
        <a href="https://antlur.co" className="font-semibold" target="_blank" rel="noreferrer noopener">
          Antlur Creative
        </a>
      </div>
    </div>
  );
}

"use client";
import { useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

function loadScript() {
  console.log(loadScript);
  const script = document.createElement("script");
  script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  script.async = true;
  script.onload = () => {
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          includedLanguages: "en,es,pl",
          // layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        },
        "google_translate_element"
      );
    };
  };
  document.body.appendChild(script);
  return script;
}

export function GoogleTranslate() {
  const [script, setScript] = useState<any>(null);

  function loadScriptIfNotLoaded() {
    if (!script) {
      setScript(loadScript());
    }
  }

  return (
    <>
      <div className="relative language-picker skiptranslate __js header__language-picker __visible-l">
        <Select
          id="header-language_select"
          name="language-chooser"
          className="text-sm py-2 border rounded-md px-3"
          onOpenChange={(isOpen) => {
            if (isOpen) {
              loadScriptIfNotLoaded();
            }
          }}
          onValueChange={(value) => {
            const selectField = document.querySelector(".goog-te-combo");
            if (selectField) {
              selectField.value = value;
              selectField.dispatchEvent(new Event("change"));
            }
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Translate Site" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="en">English</SelectItem>
            <SelectItem value="es">Spanish</SelectItem>
            <SelectItem value="pl">Polish</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div id="google_translate_element" className="hidden"></div>
    </>
  );
}
